import React, {Component} from 'react';
import Modal from '../Modal'
import {getCustomer, updateCustomer} from '../../data/customers'
import {connect} from 'react-redux'
import FormProvider from "../FormProvider";
import Input from "../Input";
import Textarea from "../Textarea";
import Select from "../Select";
import SelectSearch from "react-select";
import {getCustomerCountryOptions, getCustomerCurrencyOptions} from "../../helpers";

class CustomerUpdateModal extends Component
{
    constructor(props) {
        super(props);

        this.update = this.update.bind(this);
    }


    state = {
        name: "",
        address: "",
        phone: "",
        siret: "",
        status:"ongoing",
        color: "",
        country: { label: 'France', value: 'France' },
        company_shape: '',
        social_capital: '',
        social_capital_currency: {},
        rcs_number: '',
        rcs_country: '',
        tva_number: ''
    };

    componentDidMount()
    {
        this.props.getCustomer(this.props.id).then((res) => {
            let data = res.payload.data;
            this.setState({
                address: data.address,
                name: data.name,
                phone: data.phone,
                siret: data.siret,
                status: data.status,
                color: data.color,
                country: data.country,
                company_shape: data.company_shape,
                social_capital: data.social_capital,
                social_capital_currency: data.social_capital_currency,
                rcs_number: data.rcs_number,
                rcs_country: data.rcs_country,
                tva_number: data.tva_number
            })
        });
    }

    update(event)
    {
        event.preventDefault();
        let {name, address, phone, siret, status, color, country, company_shape, social_capital, social_capital_currency, rcs_number, rcs_country, tva_number} = this.state;
        this.props.updateCustomer(this.props.id, name, phone, address, siret, status, color, country ? country.value : 'France', company_shape, social_capital, social_capital_currency ? social_capital_currency.value : '', rcs_number, rcs_country, tva_number)
            .then((res) => {
                this.props.onClose(true);
            });
    }

    render = () => {
        return (
            <Modal title="Update Customer" onClose={this.props.onClose}>
                <FormProvider onSubmit={this.update}>
                    <Input
                        type={"text"}
                        name={"name"}
                        id={"name"}
                        placeholder={"Name"}
                        value={this.state.name}
                        onChange={(name) => this.setState({ name: name})}
                        label={"Name"}
                    />
                    <Textarea
                        type={"text"}
                        name={"address"}
                        id={"address"}
                        placeholder={"Address"}
                        value={this.state.address}
                        onChange={(address) => this.setState({ address: address})}
                        label={"Address (Shows with new lines in quotation)"}
                    />
                    <Input
                        type={"text"}
                        name={"phone"}
                        id={"phone"}
                        placeholder={"Phone"}
                        value={this.state.phone}
                        onChange={(phone) => this.setState({ phone: phone})}
                        label={"Phone"}
                    />
                    <Input
                        type={"text"}
                        name={"siret"}
                        id={"siret"}
                        placeholder={"siret"}
                        value={this.state.siret}
                        onChange={(siret) => this.setState({ siret: siret})}
                        label={"Siret"}
                    />
                    <Input
                        type={"color"}
                        name={"color"}
                        id={"color"}
                        placeholder={"color"}
                        value={this.state.color}
                        onChange={(color) => this.setState({ color })}
                        label={"Color"}
                    />
                    <div className="form-group">
                        <SelectSearch
                            name={"country"}
                            placeholder="Select a Country"
                            options={getCustomerCountryOptions().map(c => ({ label: c.nom_fr_fr, value: c.nom_fr_fr }))}
                            value={this.state.country}
                            onChange={country => this.setState({ country })}
                        />
                    </div>
                    <Input
                        type={"text"}
                        name={"company_shape"}
                        id={"company_shape"}
                        placeholder={"Company Shape"}
                        value={this.state.company_shape}
                        onChange={company_shape => this.setState({ company_shape })}
                        label={"Company Shape"}
                    />
                    <Input
                        type={"text"}
                        name={"social_capital"}
                        id={"social_capital"}
                        placeholder={"Social Capital"}
                        value={this.state.social_capital}
                        onChange={social_capital => this.setState({ social_capital })}
                        label={"Social Capital"}
                    />
                    <div className="form-group">
                        <SelectSearch
                            name={"social_capital_currency"}
                            placeholder="Select a Capital Currency"
                            options={getCustomerCurrencyOptions().map(c => ({ label: c, value: c }))}
                            value={this.state.social_capital_currency}
                            onChange={social_capital_currency => this.setState({ social_capital_currency })}
                        />
                    </div>
                    <Input
                        type={"text"}
                        name={"rcs_number"}
                        id={"rcs_number"}
                        placeholder={"RCS Number"}
                        value={this.state.rcs_number}
                        onChange={rcs_number => this.setState({ rcs_number })}
                        label={"RCS Number"}
                    />
                    <Input
                        type={"text"}
                        name={"rcs_country"}
                        id={"rcs_country"}
                        placeholder={"Ville RCS"}
                        value={this.state.rcs_country}
                        onChange={rcs_country => this.setState({ rcs_country })}
                        label={"Ville RCS"}
                    />
                    <Input
                        type={"text"}
                        name={"tva_number"}
                        id={"tva_number"}
                        placeholder={"Numéro de TVA"}
                        value={this.state.tva_number}
                        onChange={tva_number => this.setState({ tva_number })}
                        label={"Numéro de TVA"}
                    />
                    <button className="btn btn-primary">Mettre à jour</button>
                </FormProvider>
            </Modal>
        )
    }
}

CustomerUpdateModal.defaultProps = {
    id: null,
    onClose: false
};

export default connect(null, {getCustomer, updateCustomer})(CustomerUpdateModal);
